import { Box } from "grommet";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import photo_1 from "../images/lookbook/take_it_easy/take_it_easy_1@2x.jpg";








if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals("ScrollTrigger", ScrollTrigger);


  // gsap.config({
  //   force3D: true,
  //   // units: {left: "%", top: "%", rotation: "rad"}
  // });
}

const BackDrop = styled.div`
  background-image: ${(props) => `url("${props.src}")`};
  max-width: calc((100vw - 160px) * 0.9);
  width: 90%;
  height: 90%;
  left: 0;
  right: 0;
  padding: 50px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  top: 0;
  background-size: cover;
  z-index: 0;
  border-radius: 30px;
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  background-image: ${(props) => `url("${props.src}")`};
  width: 100%;
  height: 0;
  padding-bottom: 47%;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  bottom: 0;
  background-size: 95% auto;
  z-index: 2;
`;

const TitleText = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  top: 20%;
  background-size: 100% auto;
  z-index: 1;
`;

const MiddlegroundImage = styled.div`
  background-image: ${(props) => `url("${props.src}")`};
  width: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  height: 0;
  padding-bottom: 26.3%;
  position: absolute;
  bottom: 5px;
  background-size: 98% auto;
  z-index: 3;
`;

const ForegroundImage = styled.div`
  background-image: ${(props) => `url("${props.src}")`};
  width: 100%;
  height: 0;
  padding-bottom: 26.3%;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  bottom: 10px;
  background-size: auto 100%;
  z-index: 4;
`;

const Person = styled.div`
  background-image: ${(props) => `url("${props.src}")`};
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  bottom: 7%;
  padding-bottom: 45%;
  height: 0;
  width: 15%;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-size: 100% auto;
  z-index: 5;
  pointer-events: "auto";
`;

const RoundImage = React.forwardRef(({ src }, ref) => {
  return (
    <Box ref={ref} round fill="horizontal" style={{ overflow: "hidden", zIndex: 1000, position: "relative" }}>
      <img src={src} width="100%" />
    </Box>
  );
});

const ItemImage = React.forwardRef(({ src, ...rest }, ref) => {
  return (
    <Box {...rest} ref={ref}>
      <img src={src} />
    </Box>
  );
});

const CuriousScreen = React.forwardRef(({ sectionNumber, style }, ref) => {
  // const canScroll = useSelector((state) => state.scroller.canScroll[sectionNumber]);
  const heroRef = useRef(null);
  const scrollerRef = useRef(null);

  useEffect(() => {

    ScrollTrigger.scrollerProxy(scrollerRef.current, {	pinType: "fixed" });

    let panelTl = gsap.timeline({
      scrollTrigger: {
        scroller: scrollerRef.current,
        trigger: heroRef.current,
        start: `top 0px`,
        end: `200`,
        pin: heroRef.current,
        pinSpacing: true,
        // pinReparent: true,
        invalidateOnRefresh: true,
        anticipatePin: 1,
        markers: true,
      },
    });
  }, []);

  return (
    <div style={{ position: "absolute", width: "calc(100vw - 160px)", marginLeft: 80, marginRight: 80 }}>
      <div
        className="scroll-section"
        style={{ background: "black", top: 80, left: 0, height: "calc(100vh - 160px)", width: "calc(100vw - 160px)", position: "absolute", overflow: "hidden" }}>
        <div ref={scrollerRef} style={{ height: "100%", overflowY: "scroll", width: "100%", position: "relative" }}>
          <div style={{ height: "2000px", background: "blue" }}>
              <div ref={heroRef}  src={photo_1} style={{top: 0,  height: 300, width: 100, background: "green" }} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default CuriousScreen;
